import BN from "bn.js";
import { atom, atomFamily } from "recoil";
import { Recipient } from "../components/CreateProposalWizard/steps/sendAssets/ProposalWizardSendTokens";
import { NFT } from "../components/CreateProposalWizard/steps/sendAssets/ProposalWizardSendTokens/components/NFTItem";
import { localStorageEffect } from "./notifications";

export const councilMembersFormInput = atom<string[]>({
  key: "forms/wizard/councilStep",
  default: [],
});

export const thresholdsFormInput = atom<{
  treasuryThreshold: number;
  configThreshold: number;
} | null>({
  key: "forms/wizard/thresholdsStep",
  default: null,
});

export const organisationConfigFormInput = atom<{
  rankingPeriod: number;
  proposalMinReputationGate: number;
  collectionItems: {
    mint: string;
    repMultiplier: number;
  }[];
} | null>({
  key: "forms/wizard/organisationConfig",
  default: null,
});

export const organisationProfileFormInput = atom<{
  handle: string;
  displayName: string;
  pfpMint: string;
} | null>({
  key: "forms/wizard/organisationProfile",
  default: null,
});

export const previousCreatedOrganisations = atomFamily<
  {
    oragnisation: {
      organizationIdentifier: string;
      organizationAddress: string;
      sig: string[];
      treasuryWallet: string;
      treasuryConfigAddress: string;
      organisationAuthorityConfigAddress: string;
    } | null;
    // Hash of config and council members
    configHash: string | null;
    profileProposal: string | null;
    date: number | null;
  },
  string | undefined
>({
  key: "forms/wizard/previousCreatedOrganisations",
  default: {
    oragnisation: null,
    configHash: null,
    profileProposal: null,
    date: null,
  },
  effects: (key) => [localStorageEffect(`align_prev_created_org_${key}`)],
});

export const proposalWizardServicerStepInputs = atom<{
  servicer: {
    handle: string;
    identifier: string;
    displayname: string;
  } | null;
  mint: string | null;
  mintDecimals: number;
  uiAmount: number;
  walletConfig: string;
} | null>({
  key: "forms/wizard/proposal/servicer",
  default: null,
});

export const proposalWizardDescriptionStepInputs = atom<{
  title: string;
  description: string;
} | null>({
  key: "forms/wizard/proposal/description",
  default: null,
});

export const proposalWizardcouncilMembersStepInputs = atom<{
  toAdd: {
    username: string;
    identifier: string;
  }[];
  toRemove: {
    username: string;
    identifier: string;
  }[];
} | null>({
  key: "forms/wizard/proposal/councilMembers",
  default: null,
});

export const proposalWizardGovernanceThresholdStepInputs = atom<{
  thresholdChanges: {
    walletConfigAddress: string;
    name: string;
    newThreshold: number;
  }[];
} | null>({
  key: "forms/wizard/proposal/threshold",
  default: null,
});

export const proposalWizardSendAssetsRecipients = atom<
  | {
      id: number;
      walletAddress: string;
      name: string;
    }[]
  | null
>({
  key: "forms/wizard/proposal/recipients",
  default: null,
});

export const proposalWizardBonkAmount = atom<BN>({
  key: "forms/wizard/proposal/bonk/amount",
  default: new BN(0),
});

export const proposalWizardSendAssetsDescription = atom<{
  title: string;
  description: string;
  hasRanking: boolean;
} | null>({
  key: "forms/wizard/proposal/send/description",
  default: null,
});

export const proposalWizardSendAssetsTokens = atom<Record<
  string,
  {
    amount: string;
    decimals: number;
    recipient: string | number;
    // programId: string;
  }[]
> | null>({
  key: "forms/wizard/proposal/tokens",
  default: null,
});

export const proposalWizardSendAssetsNFTs = atom<Record<
  string,
  { nft: NFT; recipient: Recipient }
> | null>({
  key: "forms/wizard/proposal/nfts",
  default: null,
});
