import AlignLogo from "../components/AlignLogo"
import alignByPhaseLabs from "../assets/alignbyphaselabs.svg";

const Maintenance = () => {
    return ( <div className="flex flex-col min-h-screen justify-center items-center gap-10 mx-auto max-w-screen-2xl  md:px-8 px-1 py-1 text-contrast">
        
        <img
                alt="align by phase labs"
                className="w-[50px] object-cover object-left h-[40px] overflow-hidden sm:object-contain sm:h-16 max-w-[30vw] md:max-w-[240px] sm:w-auto"
                src={alignByPhaseLabs}
            >

            </img>
        <div className="text-center">
            <p className="font-light text-2xl mb-4 text-accent">Align V1 is now discontinued.</p> Please use Align V2 <a className="text-accent" href="http://align.nexus">here</a>
        </div>
    </div> );
}
 
export default Maintenance;